/*

import React, { useState, useEffect } from 'react';
import "./Sidenav.css";
import { auth, db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, where, query } from 'firebase/firestore';
import Logo from '../../assets/logo.png'
import { IoChatbubble } from "react-icons/io5";
import { IoSearch } from "react-icons/io5"
import { IoIosAdd } from "react-icons/io";
import MainChatFeed from '../../components/comChat/chatFeed/MainChatFeed'
import SettingButtonAccount from './settingButtonAccount/settingButtonAccount'
import { FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Sidenav({ setIsAdding, handleEdit }) {

  const [userVal, setUserVal] = useState([]);
  const [companyVal, setCompanyVal] = useState([]);
  const user1 = auth.currentUser;
  const navigate = useNavigate();

  const [displayText, setDisplayText] = useState('Sign In');

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText(prevText =>
        prevText === t('Sign In') ?  t('Create your account') : t('Sign In')
      );
    }, 5000); // 10 seconds

    return () => clearInterval(interval);
  }, []);
 

  useEffect(() => {
    const getData = async () => {
      if (user1) {
        const { uid } = user1;

        const userQuery = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const companyQuery = query(collection(db, 'company'), where('uid', '==', uid) );

        try {
          const userSnapshot = await getDocs(userQuery);
          setUserVal(userSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));

          const companySnapshot = await getDocs(companyQuery);
          setCompanyVal(companySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
          console.error('Error getting data:', error);
        }
      }
    };

    getData();
  }, [user1]);

  
        const goPerfil = () => {
          if (user1) {
            navigate(`/Perfil/${user1.uid}`);  
          }
        }

        const goPost = () => {
          if (user1) {
            navigate("/Post");  
          }
        }

      function goChat () {
        navigate("/Chat")
      }

      function goSearch () {
        navigate("/SearchJob")
      }

      function goQuery() {
        navigate("/Search");
    }

  return (
    
    <div className="containerSIDENA">
      
      <div className="containerB ">
      <button className="buttlogoICON" onClick={goSearch}>
                <img className="logo" src={Logo} alt="Search" />
            </button>
        <button className="buttlogo" onClick={goQuery}>
           <IoSearch className="search-icon" size={42} />
        </button> 

        <button className="glow-on-hoverPost" onClick={() => {
        goPost(); // Navigate to goPerfil
       
      }}>
           <IoIosAdd className="search-icon" size={52}  />
         </button>

        <button className="buttlogo" onClick={goChat}>
            <IoChatbubble className="search-icon" size={40}/>
        </button>
        


        {companyVal.map((value, index) => (
          <button className="buttlogo" onClick={goPerfil}> 
            <div className="userImg" key={`company-${index}`}>
              {value.imgPerfil ? (
                <img src={value.imgPerfil} alt="Company Profile" className="userImg" />
              ) : (
                <div className="no-image-44">
                 <FaUser size={28} className="search-icon" />
             </div>
              )}
            </div>
            </button>
          ))}

        
        {userVal.length > 0 && (
        <button className="buttlogo" onClick={goPerfil}>
          {userVal.map((value, index) => (
            <div className="userImg" key={`user-${index}`}>
              {value.imgPerfil ? (
                <img src={value.imgPerfil} alt="User Profile" className="userImg" />
              ) : (
                <div className="no-image-44">
                <FaUser size={28} className="search-icon" />
             </div>
              )}
            </div>
          ))}

        </button>  
)}
 
 {user1 && <SettingButtonAccount  size={50} user1={user1} handleEdit={handleEdit} />}
       
    
    </div>

    {!user1 && (
        <div className="containerD">
            <button className="glow-on-hover1" onClick={() => navigate('/login')}>
              <div className="texANOW12">
                  {displayText}
              </div>
            </button>
        </div>
      )}

{user1 && userVal.length === 0 && companyVal.length === 0 && (
    <div className="containerD">
        <button className="glow-on-hover1" onClick={() => navigate('/TipeAccount')}>
            <div className="texANOW12">
                Finish setting up your account
            </div>
        </button>
    </div>
)}

      
      {user1 && (
    <div className="containerC ">
       <MainChatFeed/>      
    </div>
  )}
      
   </div>
  );
}
//<VerificationSidena/>  

export default Sidenav;

*/
import React, { useState, useEffect } from 'react';
import "./Sidenav.css";
import { auth, db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, where, query } from 'firebase/firestore';
import Logo from '../../assets/logo.png';
import { IoChatbubble } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { IoIosAdd } from "react-icons/io";
import MainChatFeed from '../../components/comChat/chatFeed/MainChatFeed';
import SettingButtonAccount from './settingButtonAccount/settingButtonAccount';
import { FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SignUp from '../../pages/SignUp';
import { useMediaQuery } from 'react-responsive';

function Sidenav({ setIsAdding, handleEdit }) {
  const [userVal, setUserVal] = useState([]);
  const [companyVal, setCompanyVal] = useState([]);
  const user1 = auth.currentUser;
  const navigate = useNavigate();
  const [displayText, setDisplayText] = useState('Sign In');
  const { t, i18n } = useTranslation();

  // Media Queries for responsiveness
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText(prevText =>
        prevText === t('Sign In') ? t('Create your account') : t('Sign In')
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    const getData = async () => {
      if (user1) {
        const { uid } = user1;

        const userQuery = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const companyQuery = query(collection(db, 'company'), where('uid', '==', uid));

        try {
          const userSnapshot = await getDocs(userQuery);
          setUserVal(userSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));

          const companySnapshot = await getDocs(companyQuery);
          setCompanyVal(companySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
          console.error('Error getting data:', error);
        }
      }
    };

    getData();
  }, [user1]);

  const goPerfil = () => {
    if (user1) {
      navigate(`/Perfil/${user1.uid}`);
    }
  };

  const goPost = () => {
    if (user1) {
      navigate("/Post");
    }
  };

  const goChat = () => {
    navigate("/Chat");
  };

  const goSearch = () => {
    navigate("/SearchJob");
  };

  const goQuery = () => {
    navigate("/Search");
  };

  return (
    <div className="containerSIDENA">
      <div className="containerB">
        <button className="buttlogoICON" onClick={goSearch}>
          <img className="logo" src={Logo} alt="Search" />
        </button>
        <button className="buttlogo" onClick={goQuery}>
          <IoSearch className="search-icon" size={42} />
        </button>

        {isMobile && !user1 && (
          <button className="glow-on-hover1" onClick={() => navigate('/login')}>
            <div className="texANOW12">
              {displayText}
            </div>
          </button>
          )}

        {user1 && (
          <button className="glow-on-hoverPost" onClick={goPost}>
            <IoIosAdd className="search-icon" size={52} />
          </button>
        )}

        {user1 && (
          <button className="buttlogo" onClick={goChat}>
            <IoChatbubble className="search-icon" size={40} />
          </button>
        )}

        {companyVal.map((value, index) => (
          <button className="buttlogo" onClick={goPerfil} key={`company-${index}`}>
            <div className="userImg">
              {value.imgPerfil ? (
                <img src={value.imgPerfil} alt="Company Profile" className="userImg" />
              ) : (
                <div className="no-image-44">
                  <FaUser size={28} className="search-icon" />
                </div>
              )}
            </div>
          </button>
        ))}

        {userVal.length > 0 && (
          <button className="buttlogo" onClick={goPerfil}>
            {userVal.map((value, index) => (
              <div className="userImg" key={`user-${index}`}>
                {value.imgPerfil ? (
                  <img src={value.imgPerfil} alt="User Profile" className="userImg" />
                ) : (
                  <div className="no-image-44">
                    <FaUser size={28} className="search-icon" />
                  </div>
                )}
              </div>
            ))}
          </button>
        )}

        {user1 && <SettingButtonAccount size={50} user1={user1} handleEdit={handleEdit} />}
      </div>

      {user1 && userVal.length === 0 && companyVal.length === 0 && (
        <div className="containerD">
          <button className="glow-on-hover1" onClick={() => navigate('/TipeAccount')}>
            <div className="texANOW12">
              Finish setting up your account
            </div>
          </button>
        </div>
      )}

      {isDesktop && !user1 && (
        <div className="containerD">
          <SignUp />
        </div>
      )}


      {user1 && (
        <div className="containerC">
          <MainChatFeed />
        </div>
      )}
    </div>
  );
}

export default Sidenav;
