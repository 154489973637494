/*

import React, { useEffect, useState, useRef } from 'react';
import { collection, query, orderBy, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../../firebase/firebase';
import './QueryJobs.css';
import { FaUser } from 'react-icons/fa';

function QueryJobs() {
  const [jobs, setJobs] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const selectedRefs = useRef([]);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setUserId(user.uid);
    } else {
      console.error('No user is signed in.');
    }
  }, []);

  useEffect(() => {
    const q = query(collection(db, 'IntesJob'), orderBy('timestamp', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const jobsArray = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        jobsArray.push({ id: doc.id, ...data });
      });
      setJobs(jobsArray);
    });

    return () => unsubscribe();
  }, []);

  const handleJobSelect = async (job, index) => {
    try {
      if (!userId) {
        throw new Error('User ID is not provided or invalid.');
      }

      const userJobRef = doc(db, 'IntesJob', job.id, 'selectedUsers', userId);
      await setDoc(userJobRef, {
        userId: userId,
        selectedAt: new Date(),
      });

      console.log(`Job ${job.term} selected and saved for user ${userId} successfully.`);
      
      // Toggle selection
      setSelectedJobIds((prevSelectedJobIds) => {
        if (prevSelectedJobIds.includes(job.id)) {
          return prevSelectedJobIds.filter(id => id !== job.id);
        } else {
          return [...prevSelectedJobIds, job.id];
        }
      });

      // Scroll into view
      selectedRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    } catch (error) {
      console.error('Error saving job selection:', error);
    }
  };

  return (
    <div className="historial-container">
      <div className="tite3">Select jobs</div>
      <ul className="historial-list">
        {jobs.length > 0 ? (
          jobs.map((job, index) => (
            <li 
              key={index} 
              ref={(el) => selectedRefs.current[index] = el} // Set ref for each item
              className={`historial-item ${selectedJobIds.includes(job.id) ? 'selected' : ''}`} 
              onClick={() => handleJobSelect(job, index)} // Pass index to the handler
            >
              {job.term}
            </li>
          ))
        ) : (
          <li className="no-history">No jobs found.</li>
        )}
      </ul>
    </div>
  );
}

export default QueryJobs;

*/
import React, { useEffect, useState, useRef } from 'react';
import { collection, query, orderBy, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../../firebase/firebase';
import './QueryJobs.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function QueryJobs() {
  const [jobs, setJobs] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const selectedRefs = useRef([]);
  const navigate = useNavigate(); 

  const { t } = useTranslation();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setUserId(user.uid);
    } else {
      console.error('No user is signed in.');
    }
  }, []);

  useEffect(() => {
    const q = query(collection(db, 'IntesJob'), orderBy('timestamp', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const jobsArray = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        jobsArray.push({ id: doc.id, ...data });
      });
      setJobs(jobsArray);
    });

    return () => unsubscribe();
  }, []);

  const handleJobSelect = async (job, index) => {
    try {
      if (!userId) {
        throw new Error('User ID is not provided or invalid.');
      }

      const userJobRef = doc(db, 'IntesJob', job.id, 'selectedUsers', userId);
      await setDoc(userJobRef, {
        userId: userId,
        selectedAt: new Date(),
      });

      console.log(`Job ${job.term} selected and saved for user ${userId} successfully.`);
      
      // Toggle selection
      setSelectedJobIds((prevSelectedJobIds) => {
        if (prevSelectedJobIds.includes(job.id)) {
          return prevSelectedJobIds.filter(id => id !== job.id);
        } else {
          return [...prevSelectedJobIds, job.id];
        }
      });

      // Scroll into view
      selectedRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    } catch (error) {
      console.error('Error saving job selection:', error);
    }
  };

  const handleContinue = () => {
    navigate('/Cv'); // Navega a /Search
  };

  return (
    <div className="historial-container">
     <div className="container32">
        <div className="tite3">{t('Select jobs')}</div>
        {selectedJobIds.length > 1 && (
          <button className="continue-button" onClick={handleContinue}>
              {t('Next')}
          </button>
        )}
      </div>

      <ul className="historial-list">
        {jobs.length > 0 ? (
          jobs.map((job, index) => (
            <li 
              key={index} 
              ref={(el) => selectedRefs.current[index] = el} // Set ref for each item
              className={`historial-item ${selectedJobIds.includes(job.id) ? 'selected' : ''}`} 
              onClick={() => handleJobSelect(job, index)} // Pass index to the handler
            >
              {job.term}
            </li>
          ))
        ) : (
          <li className="no-history">No jobs found.</li>
        )}
      </ul>
    </div>
  );
}

export default QueryJobs;
