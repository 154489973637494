/*


import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useSpring, animated } from '@react-spring/web';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import NoMoreCard from '../NoMoreCard/NoMoreCard';
import UiCards from '../UiCards/UiCards';  
import Modal from '../ModalSwiper/Modal';  
import './SwipeableCard.css';
import ProfileImage from '../ProfileImage/ProfileImage.js'

function SwipeableCard({ postId }) {
  const [data, setData] = useState({ company: [], infoUser: [], employeesPost: [] });
  const [currentUid, setCurrentUid] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(() => {
    const savedIndex = localStorage.getItem('currentCardIndex');
    return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
  });
  const [showModal, setShowModal] = useState(false);  
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [springProps, setSpringProps] = useSpring(() => ({
    x: 0,
    y: 0,
    config: { tension: 500, friction: 20 },
  }));

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUid(user.uid);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const companyData = await getDocs(collection(db, 'company'));
      const infoUserData = await getDocs(collection(db, 'infoUser'));
      const employeesPostData = await getDocs(collection(db, 'employeesPost'));

      setData({
        company: companyData.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'company' })),
        infoUser: infoUserData.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'infoUser' })),
        employeesPost: employeesPostData.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'employeesPost' })),
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (videoRef.current && videoRef.current.tagName === 'VIDEO') {
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
      });
    }
  }, [currentCardIndex]);

  useEffect(() => {
    localStorage.setItem('currentCardIndex', currentCardIndex);
  }, [currentCardIndex]);

  const handleNavigateToProfile = useCallback(
    (id) => {
      navigate(`/Perfil/${id}`);
    },
    [navigate]
  );

  const handleSwipeEnd = (eventData) => {
    if (videoRef.current && videoRef.current.tagName === 'VIDEO') {
      videoRef.current.pause(); // Pause video before swipe
    }
    if (eventData.dir === 'Left' || eventData.dir === 'Right') {
      triggerAnimation(eventData.dir);
    } else {
      resetPosition();
    }
  };

  const triggerAnimation = (direction) => {
    setSpringProps({
      x: direction === 'Left' ? -1000 : 1000,
      onRest: () => {
        setTimeout(() => {
          setCurrentCardIndex((prevIndex) => prevIndex + 1);
          resetPosition();
        }, 300); // Delay the update
      },
    });
  };

  const resetPosition = () => {
    setSpringProps({ x: 0, y: 0 });
  };

  const handleYes = () => {
    setShowConfetti(true);
    triggerAnimation('Right');
    setTimeout(() => setShowConfetti(false), 1500);
  };

  const handleNo = () => {
    triggerAnimation('Left');
  };

  const handleReload = () => {
    setCurrentCardIndex(0);
    localStorage.setItem('currentCardIndex', 0);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeEnd,
    onSwipedRight: handleSwipeEnd,
    onSwiping: ({ deltaX, deltaY }) => {
      setSpringProps({ x: deltaX, y: deltaY });
    },
  });

  const currentData = data.employeesPost.concat(data.infoUser, data.company);

  useEffect(() => {
    if (postId) {
      const index = currentData.findIndex(card => card.id === postId);
      if (index !== -1 && currentCardIndex !== index) {
        setCurrentCardIndex(index);
      }
    }
  }, [postId, currentData, currentCardIndex]);

  const currentCard = currentData[currentCardIndex];

  const isMobile = window.innerWidth <= 768;

  if (!currentCard) {
    return <NoMoreCard onReload={handleReload} />;
  }

  return (
    <div className="posts-container65-43">
      <div className="content-wrapper">
        <animated.div
          {...handlers}
          className="post-media-wrapper"
          style={{ transform: springProps.x.to((x) => `translateX(${x}px)`) }}
          onClick={() => handleNavigateToProfile(currentCard.uid)}
        >
          <div className="post-media-43">
            {currentCard.videoPost ? (
              <video
                ref={videoRef}
                className="post-media-43"
                controls
                onLoadedMetadata={event => {
                  event.target.style.width = '100%';
                  event.target.style.height = 'auto';
                }}
              >
                <source src={currentCard.videoPost} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : currentCard.imgPost || currentCard.selectedImage ? (
              <img
                className="post-media-43"
                src={currentCard.imgPost || currentCard.selectedImage}
                alt="Profile"
                onLoad={event => {
                  event.target.style.width = '100%';
                  event.target.style.height = 'auto';
                }}
              />
            ) : currentCard.imgPerfil ? (
              <img
                className="post-media-43"
                src={currentCard.imgPerfil}
                alt="Profile"
                onLoad={event => {
                  event.target.style.width = '100%';
                  event.target.style.height = 'auto';
                }}
              />
            ) : (
              <div className="maincon43">
                <div className="post-icon-text post-des2-43">
                  No image yet
                </div>
              </div>
            )}
            
          </div>

          {isMobile && (
            <div className="post-column">
              <div className="post-row43">
                <ProfileImage uid={currentCard.uid} /> 
              <div className="post-icon-text post-des2-43">
                  {currentCard.inputText}  
              </div>
                
                {(currentCard.firstName || currentCard.lastName || currentCard.city) && (
                  <>
                    {(currentCard.firstName || currentCard.lastName) && (
                      <div className="post-icon-text post-des2-43">
                        {currentCard.firstName}  {currentCard.lastName}
                      </div>
                    )}
                    
                    {currentCard.city && (
                      <div className="post-icon-text post-des2-43">
                        {currentCard.city}
                      </div>
                    )}
                  </>
                )}
                
              </div>
          </div>
        )}

        </animated.div>
        {isMobile ? (
          <>
            <Modal showModal={showModal} onClose={() => setShowModal(false)}>
              <UiCards currentCard={currentCard} currentUid={currentUid} />
            </Modal>
          </>
        ) : (
          <div className="customContainer">
            <UiCards currentCard={currentCard} currentUid={currentUid} />
          </div>
        )}
      </div>
      
     
      {showConfetti && <Confetti />}
        <div className="buttonContainer">
          <button onClick={() => setShowModal(true)} className="minimalButton">
            Details
          </button>
          <button className="minimalButton" onClick={handleNo}>
            Nope
          </button>
          <button className="minimalButton" onClick={handleYes}>
            Interested
          </button>
        </div>

    </div>
  );
}

export default SwipeableCard;


*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useSpring, animated } from '@react-spring/web';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import NoMoreCard from '../NoMoreCard/NoMoreCard';
import UiCards from '../UiCards/UiCards';  
import Modal from '../ModalSwiper/Modal';  
import './SwipeableCard.css';
import ProfileImage from '../ProfileImage/ProfileImage.js'

function SwipeableCard({ postId }) {
  const [data, setData] = useState({ company: [], infoUser: [], employeesPost: [] });
  const [currentUid, setCurrentUid] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(() => {
    const savedIndex = localStorage.getItem('currentCardIndex');
    return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
  });
  const [showModal, setShowModal] = useState(false);  
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [springProps, setSpringProps] = useSpring(() => ({
    x: 0,
    y: 0,
    config: { tension: 500, friction: 20 },
  }));

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUid(user.uid);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const companyData = await getDocs(collection(db, 'company'));
      const infoUserData = await getDocs(collection(db, 'infoUser'));
      const employeesPostData = await getDocs(collection(db, 'employeesPost'));

      setData({
        company: companyData.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'company' })),
        infoUser: infoUserData.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'infoUser' })),
        employeesPost: employeesPostData.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'employeesPost' })),
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (videoRef.current && videoRef.current.tagName === 'VIDEO') {
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
      });
    }
  }, [currentCardIndex]);

  useEffect(() => {
    localStorage.setItem('currentCardIndex', currentCardIndex);
  }, [currentCardIndex]);

  const handleNavigateToProfile = useCallback(
    (id) => {
      navigate(`/Perfil/${id}`);
    },
    [navigate]
  );

  const handleSwipeEnd = (eventData) => {
    if (videoRef.current && videoRef.current.tagName === 'VIDEO') {
      videoRef.current.pause(); // Pause video before swipe
    }
    if (eventData.dir === 'Left' || eventData.dir === 'Right') {
      triggerAnimation(eventData.dir);
    } else {
      resetPosition();
    }
  };

  const triggerAnimation = (direction) => {
    setSpringProps({
      x: direction === 'Left' ? -1000 : 1000,
      onRest: () => {
        setTimeout(() => {
          setCurrentCardIndex((prevIndex) => Math.min(prevIndex + 1, currentData.length - 1)); // Prevent overflow
          resetPosition();
        }, 300); // Delay the update
      },
    });
  };

  const resetPosition = () => {
    setSpringProps({ x: 0, y: 0 });
  };

  const handleYes = () => {
    setShowConfetti(true);
    triggerAnimation('Right');
    setTimeout(() => setShowConfetti(false), 1500);
  };

  const handleNo = () => {
    triggerAnimation('Left');
  };

  const handleReload = () => {
    setCurrentCardIndex(0);
    localStorage.setItem('currentCardIndex', 0);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeEnd,
    onSwipedRight: handleSwipeEnd,
    onSwiping: ({ deltaX, deltaY }) => {
      setSpringProps({ x: deltaX, y: deltaY });
    },
  });

  const currentData = data.employeesPost.concat(data.infoUser, data.company);

  // Effect to handle postId change and display the specified item
  const [postIdProcessed, setPostIdProcessed] = useState(false);

useEffect(() => {
  if (postId && !postIdProcessed) {
    const index = currentData.findIndex(card => card.id === postId);
    
    // If the postId matches and it's not already the current card
    if (index !== -1 && currentCardIndex !== index) {
      setCurrentCardIndex(index);
      setPostIdProcessed(true);  // Mark that we've processed the postId
    }
  }
}, [postId, currentData, currentCardIndex, postIdProcessed]);

// Reset the postIdProcessed state when the postId changes (if needed)
useEffect(() => {
  if (postId) {
    setPostIdProcessed(false);  // Allow a new postId to trigger the effect
  }
}, [postId]);


 
  const currentCard = currentData[currentCardIndex];

  const isMobile = window.innerWidth <= 768;

  if (!currentCard) {
    return <NoMoreCard onReload={handleReload} />;
  }

  return (
    <div className="posts-container65-43">
      <div className="content-wrapper">
        <animated.div
          {...handlers}
          className="post-media-wrapper"
          style={{ transform: springProps.x.to((x) => `translateX(${x}px)`) }}
          onClick={() => handleNavigateToProfile(currentCard.uid)}
        >
          <div className="post-media-43">
            {currentCard.videoPost ? (
              <video
                ref={videoRef}
                className="post-media-43"
                controls
                onLoadedMetadata={event => {
                  event.target.style.width = '100%';
                  event.target.style.height = 'auto';
                }}
              >
                <source src={currentCard.videoPost} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : currentCard.imgPost || currentCard.selectedImage ? (
              <img
                className="post-media-43"
                src={currentCard.imgPost || currentCard.selectedImage}
                alt="Profile"
                onLoad={event => {
                  event.target.style.width = '100%';
                  event.target.style.height = 'auto';
                }}
              />
            ) : currentCard.imgPerfil ? (
              <img
                className="post-media-43"
                src={currentCard.imgPerfil}
                alt="Profile"
                onLoad={event => {
                  event.target.style.width = '100%';
                  event.target.style.height = 'auto';
                }}
              />
            ) : (
              <div className="maincon43">
                <div className="post-icon-text post-des2-43">
                  No image yet
                </div>
              </div>
            )}
            
          </div>

          {isMobile && (
            <div className="post-column">
              <div className="post-row43">
                <ProfileImage uid={currentCard.uid} /> 
              <div className="post-icon-text post-des2-43">
                  {currentCard.inputText}  
              </div>
                
                {(currentCard.firstName || currentCard.lastName || currentCard.city) && (
                  <>
                    {(currentCard.firstName || currentCard.lastName) && (
                      <div className="post-icon-text post-des2-43">
                        {currentCard.firstName}  {currentCard.lastName}
                      </div>
                    )}
                    
                    {currentCard.city && (
                      <div className="post-icon-text post-des2-43">
                        {currentCard.city}
                      </div>
                    )}
                  </>
                )}
                
              </div>
          </div>
        )}

        </animated.div>
        {isMobile ? (
          <>
            <Modal showModal={showModal} onClose={() => setShowModal(false)}>
              <UiCards currentCard={currentCard} currentUid={currentUid} />
            </Modal>
          </>
        ) : (
          <div className="customContainer">
            <UiCards currentCard={currentCard} currentUid={currentUid} />
          </div>
        )}
      </div>
      
     
      {showConfetti && <Confetti />}
        <div className="buttonContainer">
          <button onClick={() => setShowModal(true)} className="minimalButton">
            Details
          </button>
          <button className="minimalButton" onClick={handleNo}>
            Nope
          </button>
          <button className="minimalButton" onClick={handleYes}>
            Interested
          </button>
        </div>

    </div>
  );
}

export default SwipeableCard;