/*

import React, { useState, useEffect } from 'react';
import { db } from '../../../../src/firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Phone, Email, Person, Translate, Wc, Language, Build, Work } from '@material-ui/icons';
import './UserCv.css';

function UserCv({ uid }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const q = query(collection(db, 'userCv'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setUserData(doc.data());
        } else {
          console.log('No such document in userCv!');
        }
      } catch (error) {
        console.error('Error getting userCv document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return null;
  }

  const truncateDescription = (description) => {
    const words = description.split(' ');
    return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : description;
  };

  return (
    <div className="user-cv-container">
      <div className="user-cv-header">
        <Person className="header-icon" />
        <div className="user-cv-title">{userData.fullName || 'User Information'}</div>
      </div>
      <div className="user-cv-content">
        <div className="user-cv-panel section-1">
          <p><strong>Age:</strong></p>
          <p>{userData.age}</p>
        </div>
        <div className="user-cv-panel section-1">
          <Wc className="detail-icon" />
          <p><strong>Gender:</strong></p>
          <p>{userData.gender}</p>
        </div>
        <div className="user-cv-panel section-1">
          <Language className="detail-icon" />
          <p><strong>Nationality:</strong></p>
          <p>{userData.nationality}</p>
        </div>
        <div className="user-cv-panel section-1">
          <Translate className="detail-icon" />
          <p><strong>English Level:</strong></p>
          <p>{userData.englishLevel}</p>
        </div>
        <div className="user-cv-panel section-2">
          <Phone className="detail-icon" />
          <p><strong className="minTwhite">Phone:</strong></p>
          <p className="DTwhite">{userData.phone}</p>
        </div>
        <div className="user-cv-panel section-2">
          <Email className="detail-icon" />
          <p><strong className="minTwhite">Email:</strong></p>
          <p className="DTwhite">{userData.email}</p>
        </div>
        <div className="user-cv-panel section-2">
          <p><strong className="minTwhite">Postal Code:</strong></p>
          <p className="DTwhite">{userData.postalCode}</p>
        </div>
        <div className="user-cv-panel section-3">
          <Build className="detail-icon" />
          <p><strong className="minTwhite">Skills:</strong></p>
          <p className="DTwhite">{truncateDescription(userData.skills)}</p>
        </div>
        <div className="user-cv-panel section-3">
          <Work className="detail-icon" />
          <p><strong className="minTwhite">Work Experience:</strong></p>
          <p className="DTwhite">{truncateDescription(userData.workExperience)}</p>
        </div>
      </div>
    </div>
  );
}

export default UserCv;



*/

import React, { useState, useEffect } from 'react';
import { db } from '../../../../src/firebase/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FaPhone, FaEnvelope, FaUser, FaLanguage, FaTransgender, FaWrench, FaBriefcase } from 'react-icons/fa';
import './UserCv.css';

function UserCv({ uid }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const q = query(collection(db, 'userCv'), where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setUserData(doc.data());
        } else {
          console.log('No such document in userCv!');
        }
      } catch (error) {
        console.error('Error getting userCv document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return null;
  }

  const truncateDescription = (description) => {
    if (!description) {
      return 'No information provided';
    }
    const words = description.split(' ');
    return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : description;
  };
  

  return (
    <div className="user-cv-container">
      <div className="user-cv-header">
        <FaUser className="header-icon" />
        <div className="user-cv-title">{userData.fullName || 'User Information'}</div>
      </div>
      <div className="user-cv-content">
        <div className="user-cv-panel section-1">
          <p><strong>Age:</strong></p>
          <p>{userData.age}</p>
        </div>
        <div className="user-cv-panel section-1">
          <FaTransgender/>
          <p><strong>Gender:</strong></p>
          <p>{userData.gender}</p>
        </div>
        <div className="user-cv-panel section-1">
          <FaLanguage />
          <p><strong>Nationality:</strong></p>
          <p>{userData.nationality}</p>
        </div>
        <div className="user-cv-panel section-1">
          <FaLanguage/>
          <p><strong>English Level:</strong></p>
          <p>{userData.englishLevel}</p>
        </div>
        <div className="user-cv-panel section-2">
          <FaPhone className="detail-icon" />
          <p><strong className="minTwhite">Phone:</strong></p>
          <p className="DTwhite">{userData.phone}</p>
        </div>
        <div className="user-cv-panel section-2">
          <FaEnvelope className="detail-icon" />
          <p><strong className="minTwhite">Email:</strong></p>
          <p className="DTwhite">{userData.email}</p>
        </div>
        <div className="user-cv-panel section-2">
          <p><strong className="minTwhite">Postal Code:</strong></p>
          <p className="DTwhite">{userData.postalCode}</p>
        </div>
        <div className="user-cv-panel section-3">
          <FaWrench className="detail-icon" />
          <p><strong className="minTwhite">Skills:</strong></p>
          <p className="DTwhite">{truncateDescription(userData.skills)}</p>
        </div>
        <div className="user-cv-panel section-3">
          <FaBriefcase className="detail-icon" />
          <p><strong className="minTwhite">Work Experience:</strong></p>
          <p className="DTwhite">{truncateDescription(userData.workExperience)}</p>
        </div>
      </div>
    </div>
  );
}

export default UserCv;
