 /*
import React, { useState } from 'react';
import './Table.css';
import { MdMoreHoriz } from 'react-icons/md';

const Table = ({ employees, handleEdit, handleDelete }) => {
  const [showInteractionsForItem, setShowInteractionsForItem] = useState(null);
  const [clickedItemIds, setClickedItemIds] = useState([]);

  const toggleInteractions = (itemId) => {
    setShowInteractionsForItem(showInteractionsForItem === itemId ? null : itemId);
    setClickedItemIds((prevState) =>
      prevState.includes(itemId) ? prevState.filter((id) => id !== itemId) : [...prevState, itemId]
    );
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: null,
  });

  return (
    <div className="posts-contTable">
      {employees ? (
        employees.map((employee, i) => (
          <div key={employee.id} className="conItemPosTa">
            <button className="buttlogo5" onClick={() => toggleInteractions(employee.id)}>
              <MdMoreHoriz className="search-icon" size={32} />
            </button>

            <div className="tesDa">
              {showInteractionsForItem === employee.id && (
                <div className="post-conEditBU">
                  <div className="center-content">  
                  {employee.description}
                  <div className="button-row">
                     
                    <button onClick={() => handleDelete(employee.id)} className="clickalicious-button2">
                      <text className="eTP">Delete</text>
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="tesDa">
              {employee.selectedImage && (
                <img
                  className={`post-imageTa ${
                    clickedItemIds.includes(employee.id) ? 'filtered-image' : ''
                  }`}
                  src={employee.selectedImage}
                  alt=""
                />
              )}

              {employee.imgPost && (
                <img
                  className={`post-imageTa ${
                    clickedItemIds.includes(employee.id) ? 'filtered-image' : ''
                  }`}
                  src={employee.imgPost}
                  alt=""
                />
              )}

            <div className="post-info">

              {employee.title && <div className="post-00ADD">{employee.title}</div>}
              {employee.inputText &&
               <div className={`post-00 ${
                    clickedItemIds.includes(employee.id) ? 'filtered-image' : ''}`}>{employee.inputText}
              </div>}
                                      
                    <div className="cityMONUNcon">
                          {employee.salary && (
                            <div className="columnMC">
                              <div className="aaa55">
                                  <span className="Weeklyt">Weekly</span>
                                  <div className="postSS">{formatter.format(employee.salary)}</div>
                              </div>
                            </div>
                          )}
                          {employee.city && (
                            <div className="columnMC">
                              <div className="aaa55">
                                 <span className="Weeklyt">Site</span>
                                <span className="post-likes">{employee.city}</span>
                              </div>
                            </div>
                          )}
                  </div>


              {employee.description && (
                            <div className="aaaNI">
                              <div className="PSdescription">{employee.description}</div>
                            </div>
                          )}
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div>No employees to display</div>
    )}
  </div>
);
};

export default Table;

*/ import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Table.css';
import { MdMoreHoriz } from 'react-icons/md';

const Table = ({ employees, handleEdit, handleDelete }) => {
  const [showInteractionsForItem, setShowInteractionsForItem] = useState(null);
  const [clickedItemIds, setClickedItemIds] = useState([]);
  const navigate = useNavigate();

  const handleNavigateToSearchJob = useCallback((postId) => {
    navigate(`/SearchJob`, { state: { postId } });
  }, [navigate]);

  const toggleInteractions = (itemId) => {
    setShowInteractionsForItem(showInteractionsForItem === itemId ? null : itemId);
    setClickedItemIds((prevState) =>
      prevState.includes(itemId) ? prevState.filter((id) => id !== itemId) : [...prevState, itemId]
    );
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: null,
  });

  return (
    <div className="posts-contTable">
      {employees ? (
        employees.map((employee, i) => (
          <div
            key={employee.id}
            className="conItemPosTa"
            onClick={() => handleNavigateToSearchJob(employee.id)} // Add this line
          >
            <button className="buttlogo5" onClick={(e) => { e.stopPropagation(); toggleInteractions(employee.id); }}>
              <MdMoreHoriz className="search-icon" size={32} />
            </button>

            <div className="tesDa">
              {showInteractionsForItem === employee.id && (
                <div className="post-conEditBU">
                  <div className="center-content">
                    {employee.description}
                    <div className="button-row">
                      <button
                        onClick={(e) => { e.stopPropagation(); handleDelete(employee.id); }}
                        className="clickalicious-button2"
                      >
                        <text className="eTP">Delete</text>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="tesDa">
                {employee.selectedImage && (
                  <img
                    className={`post-imageTa ${clickedItemIds.includes(employee.id) ? 'filtered-image' : ''}`}
                    src={employee.selectedImage}
                    alt=""
                  />
                )}

                {employee.imgPost && (
                  <img
                    className={`post-imageTa ${clickedItemIds.includes(employee.id) ? 'filtered-image' : ''}`}
                    src={employee.imgPost}
                    alt=""
                  />
                )}

                {employee.videoPost && (
                  <video
                    className="post-videoTa"
                    src={employee.videoPost}
                    controls
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    style={{ borderRadius: '10px' }}
                  />
                )}

                <div className="post-info">
                  {employee.title && <div className="post-00ADD">{employee.title}</div>}
                  {employee.inputText && (
                    <div className={`post-00 ${clickedItemIds.includes(employee.id) ? 'filtered-image' : ''}`}>
                      {employee.inputText}
                    </div>
                  )}
                  <div className="cityMONUNcon">
                    {employee.salary && (
                      <div className="columnMC">
                        <div className="aaa55">
                          <span className="Weeklyt">Weekly</span>
                          <div className="postSS">{formatter.format(employee.salary)}</div>
                        </div>
                      </div>
                    )}
                    {employee.city && (
                      <div className="columnMC">
                        <div className="aaa55">
                          <span className="Weeklyt">Site</span>
                          <span className="post-likes">{employee.city}</span>
                        </div>
                      </div>
                    )}
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No employees to display</div>
      )}
    </div>
  );
};

export default Table;
