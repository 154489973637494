 /*

 
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase';
import './ComSearch.css';
import SolicitudJob from '../SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import SendMessage from '../comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import { useTranslation } from 'react-i18next';

function MyComponent({ uids, employeesPostData }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [currentUid, setCurrentUid] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setCurrentUid(user.uid);
    } else {
      setCurrentUid(null);
    }
  }, [user]);

  const handleNavigateToProfile = useCallback((uid) => {
    navigate(`/Perfil/${uid}`);
  }, [navigate]);

  const handleNavigateToSearchJob = useCallback((postId) => {
    navigate(`/SearchJob`, { state: { postId } });
  }, [navigate]);

  const openModal = useCallback((uid) => {
    if (user) {
      setSelectedUserId(uid);
      setModalOpen(true);
    } else {
      navigate('/SignUp');
    }
  }, [user, navigate]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setSelectedUserId(null);
  }, []);

  useEffect(() => {
    const fetchInfoUserData = async () => {
      const combinedInfoUserData = [];

      const chunkSize = 30;
      for (let i = 0; i < uids.length; i += chunkSize) {
        const chunk = uids.slice(i, i + chunkSize);

        const q1 = query(collection(db, 'infoUser'), where('uid', 'in', chunk));
        const querySnapshot1 = await getDocs(q1);
        const infoUserData = querySnapshot1.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const q2 = query(collection(db, 'company'), where('uid', 'in', chunk));
        const querySnapshot2 = await getDocs(q2);
        const companyData = querySnapshot2.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (const uid of chunk) {
          const infoUser = infoUserData.find(data => data.uid === uid);
          const company = companyData.find(data => data.uid === uid);

          combinedInfoUserData.push({
            ...infoUser,
            ...company
          });
        }
      }

      setCombinedData(combinedInfoUserData);
    };

    fetchInfoUserData();
  }, [uids]);

  const formatter = useMemo(() => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: null,
  }), []);

  const handlePlayVideo = (videoId) => {
    setPlayingVideoId(videoId);
  };

  const handlePauseVideo = () => {
    setPlayingVideoId(null);
  };

  return (
    <>
      <div className="posts-container65">
        {employeesPostData.map((postData, index) => (
          <div
            key={index}
            className="post12"
            onClick={() => handleNavigateToSearchJob(employeesPostData[index]?.id)}
          >
            {postData.imgPost && (
              <img src={postData.imgPost} alt="Post" className="post-image65" />
            )}

            {postData.selectedImage && (
              <img src={postData.selectedImage} alt="Post" className="post-image65" />
            )}

            {postData.videoPost && (
              <video
                className="post-video65"
                controls
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
                onPlay={() => handlePlayVideo(postData.id)}
                onPause={handlePauseVideo}
                onEnded={handlePauseVideo}
                ref={(video) => {
                  if (video && playingVideoId !== postData.id) {
                    video.pause();
                  }
                }}
              >
                <source src={postData.videoPost} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            <div className="post-info">
              {postData.title && (
                <div className="post-title">{postData.title}</div>
              )}

              <div className="cityMONUNcon">
                {postData.salary && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Weekly salary')}</span>
                    <div className="post-description1">
                      {formatter.format(postData.salary)}
                    </div>
                  </div>
                )}

                {postData.city && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{postData.workModality}</span>
                    <span className="post-likes">{postData.city}</span>
                  </div>
                )}

                {postData.shift && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Shift')}</span>
                    <span className="post-likes">{postData.shift}</span>
                  </div>
                )}
              </div>

              <div className="cityMONUNcon">
                {postData.jobType && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Schedulle')}</span>
                    <span className="post-likes">{postData.jobType}</span>
                  </div>
                )}

                {postData.suitableFor && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Suitable For')}</span>
                    <span className="post-likes">{postData.suitableFor}</span>
                  </div>
                )}
              </div>

              <div className="aaaNI">
                <div className="PSdescription">{postData.description}</div>
              </div>

              {postData.inputText && (
                <div className="post-00ADD">
                  {postData.inputText}
                </div>
              )}

              <div className="cityMONUNcon">
                <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(postData.uid)}>
                  <div className="post-column1">
                    <img className="imgPostUser" src={combinedData[index]?.imgPerfil} alt="User Profile" />
                    {combinedData[index]?.firstName && <div className="userSpa">{combinedData[index].firstName}</div>}
                    {combinedData[index]?.nameCompany && <div className="userSpa">{combinedData[index].nameCompany}</div>}
                  </div>
                </button>

                {postData.salary && (
                  <SendMessageCompany uidThePost={postData.uid} currentUid={currentUid} />
                )}

                {postData.inputText && (
                  <div className="CCS4">
                    <SendMessage uidThePost={postData.uid} currentUid={currentUid} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && <SolicitudJob isOpen={isModalOpen} onClose={closeModal} postingUserId={selectedUserId} userId={selectedUserId} />}
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.uids.length === nextProps.uids.length;
}

export default React.memo(MyComponent, areEqual);

 
*/
 

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase';
import './ComSearch.css';
import SolicitudJob from '../SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import SendMessage from '../comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import { useTranslation } from 'react-i18next';

function MyComponent({ uids, employeesPostData }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [currentUid, setCurrentUid] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setCurrentUid(user.uid);
    } else {
      setCurrentUid(null);
    }
  }, [user]);

  const handleNavigateToProfile = useCallback((uid) => {
    navigate(`/Perfil/${uid}`);
  }, [navigate]);

  const handleNavigateToSearchJob = useCallback((postId) => {
    navigate(`/SearchJob`, { state: { postId } });
  }, [navigate]);

  const openModal = useCallback((uid) => {
    if (user) {
      setSelectedUserId(uid);
      setModalOpen(true);
    } else {
      navigate('/SignUp');
    }
  }, [user, navigate]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setSelectedUserId(null);
  }, []);

  useEffect(() => {
    const fetchInfoUserData = async () => {
      const combinedInfoUserData = [];

      const chunkSize = 30;
      for (let i = 0; i < uids.length; i += chunkSize) {
        const chunk = uids.slice(i, i + chunkSize);

        const q1 = query(collection(db, 'infoUser'), where('uid', 'in', chunk));
        const querySnapshot1 = await getDocs(q1);
        const infoUserData = querySnapshot1.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const q2 = query(collection(db, 'company'), where('uid', 'in', chunk));
        const querySnapshot2 = await getDocs(q2);
        const companyData = querySnapshot2.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (const uid of chunk) {
          const infoUser = infoUserData.find(data => data.uid === uid);
          const company = companyData.find(data => data.uid === uid);

          combinedInfoUserData.push({
            ...infoUser,
            ...company
          });
        }
      }

      setCombinedData(combinedInfoUserData);
    };

    fetchInfoUserData();
  }, [uids]);

  const formatter = useMemo(() => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: null,
  }), []);

  const handlePlayVideo = (videoId) => {
    setPlayingVideoId(videoId);
  };

  const handlePauseVideo = () => {
    setPlayingVideoId(null);
  };

  return (
    <>
      <div className="posts-container65">
        {employeesPostData.map((postData, index) => (
          <div
            key={index}
            className="post12"
            onClick={() => handleNavigateToSearchJob(employeesPostData[index]?.id)}
          >
            {postData.imgPost && (
              <img src={postData.imgPost} alt="Post" className="post-image65" />
            )}

            {postData.selectedImage && (
              <img src={postData.selectedImage} alt="Post" className="post-image65" />
            )}

            {postData.videoPost && (
              <video
                className="post-video65"
                controls
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
                onPlay={() => handlePlayVideo(postData.id)}
                onPause={handlePauseVideo}
                onEnded={handlePauseVideo}
                ref={(video) => {
                  if (video && playingVideoId !== postData.id) {
                    video.pause();
                  }
                }}
              >
                <source src={postData.videoPost} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            <div className="post-info">
              {postData.title && (
                <div className="post-title">{postData.title}</div>
              )}

              <div className="cityMONUNcon">
                {postData.salary && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Weekly salary')}</span>
                    <div className="post-description1">
                      {formatter.format(postData.salary)}
                    </div>
                  </div>
                )}

                {postData.city && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{postData.workModality}</span>
                    <span className="post-likes">{postData.city}</span>
                  </div>
                )}

                {postData.shift && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Shift')}</span>
                    <span className="post-likes">{postData.shift}</span>
                  </div>
                )}
              </div>

              <div className="cityMONUNcon">
                {postData.jobType && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Schedulle')}</span>
                    <span className="post-likes">{postData.jobType}</span>
                  </div>
                )}

                {postData.suitableFor && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Suitable For')}</span>
                    <span className="post-likes">{postData.suitableFor}</span>
                  </div>
                )}
              </div>

             

              {postData.inputText && (
                <div className="post-00ADD">
                  {postData.inputText}
                </div>
              )}

              <div className="cityMONUNcon">
                <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(postData.uid)}>
                  <div className="post-column1">
                    <img className="imgPostUser" src={combinedData[index]?.imgPerfil} alt="User Profile" />
                    {combinedData[index]?.firstName && <div className="userSpa">{combinedData[index].firstName}</div>}
                    {combinedData[index]?.nameCompany && <div className="userSpa">{combinedData[index].nameCompany}</div>}
                  </div>
                </button>

                {postData.salary && (
                  <SendMessageCompany uidThePost={postData.uid} currentUid={currentUid} />
                )}

                {postData.inputText && (
                  <div className="CCS4">
                    <SendMessage uidThePost={postData.uid} currentUid={currentUid} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && <SolicitudJob isOpen={isModalOpen} onClose={closeModal} postingUserId={selectedUserId} userId={selectedUserId} />}
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.uids.length === nextProps.uids.length;
}

export default React.memo(MyComponent, areEqual);





/*


import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase';
import './ComSearch.css';
import SolicitudJob from '../SolicitudJob/SolicitudJob';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import SendMessage from '../comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import { useTranslation } from 'react-i18next';

function MyComponent({ uids, employeesPostData }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [currentUid, setCurrentUid] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    // Set currentUid state when user object changes
    if (user) {
      setCurrentUid(user.uid);
    } else {
      setCurrentUid(null);
    }
  }, [user]);

  const handleNavigateToProfile = useCallback((uid) => {
    navigate(`/Perfil/${uid}`);
  }, [navigate]);

  const handleNavigateToSearchJob = useCallback((postId) => {
    navigate(`/SearchJob`, { state: { postId } });
  }, [navigate]);

  const openModal = useCallback((uid) => {
    if (user) {
      setSelectedUserId(uid);
      setModalOpen(true);
    } else {
      navigate('/SignUp');
    }
  }, [user, navigate]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setSelectedUserId(null);
  }, []);

  useEffect(() => {
    const fetchInfoUserData = async () => {
      const combinedInfoUserData = [];

      const chunkSize = 30;
      for (let i = 0; i < uids.length; i += chunkSize) {
        const chunk = uids.slice(i, i + chunkSize);

        const q1 = query(collection(db, 'infoUser'), where('uid', 'in', chunk));
        const querySnapshot1 = await getDocs(q1);
        const infoUserData = querySnapshot1.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const q2 = query(collection(db, 'company'), where('uid', 'in', chunk));
        const querySnapshot2 = await getDocs(q2);
        const companyData = querySnapshot2.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (const uid of chunk) {
          const infoUser = infoUserData.find(data => data.uid === uid);
          const company = companyData.find(data => data.uid === uid);

          combinedInfoUserData.push({
            ...infoUser,
            ...company
          });
        }
      }

      setCombinedData(combinedInfoUserData);
    };

    fetchInfoUserData();
  }, [uids]);

  const formatter = useMemo(() => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: null,
  }), []);

  return (
    <>
      <div className="posts-container65">
        {employeesPostData.map((postData, index) => (
          <div
            key={index}
            className="post12"
            onClick={() => handleNavigateToSearchJob(employeesPostData[index]?.id)}
          >
            {postData.imgPost && (
              <img src={postData.imgPost} alt="Post" className="post-image65" />
            )}

            {postData.selectedImage && (
              <img src={postData.selectedImage} alt="Post" className="post-image65" />
            )}

            <div className="post-info">
              {postData.title && (
                <div className="post-title">{postData.title}</div>
              )}

              <div className="cityMONUNcon">
                {postData.salary && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Weekly salary')}</span>
                    <div className="post-description1">
                      {formatter.format(postData.salary)}
                    </div>
                  </div>
                )}

                {postData.city && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{postData.workModality}</span>
                    <span className="post-likes">{postData.city}</span>
                  </div>
                )}

                {postData.shift && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Shift')}</span>
                    <span className="post-likes">{postData.shift}</span>
                  </div>
                )}
              </div>

              <div className="cityMONUNcon">
                {postData.jobType && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Schedulle')}</span>
                    <span className="post-likes">{postData.jobType}</span>
                  </div>
                )}

                {postData.suitableFor && (
                  <div className="aaa55m">
                    <span className="Weeklyt">{t('Suitable For')}</span>
                    <span className="post-likes">{postData.suitableFor}</span>
                  </div>
                )}
              </div>

              <div className="aaaNI">
                <div className="PSdescription">{postData.description}</div>
              </div>

              {postData.inputText && (
                <div className="post-00ADD">
                  {postData.inputText}
                </div>
              )}

              <div className="cityMONUNcon">
                <button className="navigatePerfilButton" onClick={() => handleNavigateToProfile(postData.uid)}>
                  <div className="post-column1">
                    <img className="imgPostUser" src={combinedData[index]?.imgPerfil} alt="User Profile" />
                    {combinedData[index]?.firstName && <div className="userSpa">{combinedData[index].firstName}</div>}
                    {combinedData[index]?.nameCompany && <div className="userSpa">{combinedData[index].nameCompany}</div>}
                  </div>
                </button>

                {postData.salary && (
                  <SendMessageCompany uidThePost={postData.uid} currentUid={currentUid} />
                )}

                {postData.inputText && (
                  <div className="CCS4">
                    <SendMessage uidThePost={postData.uid} currentUid={currentUid} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && <SolicitudJob isOpen={isModalOpen} onClose={closeModal} postingUserId={selectedUserId} userId={selectedUserId} />}
    </>
  );
}

function areEqual(prevProps, nextProps) {
  return prevProps.uids.length === nextProps.uids.length;
}

export default React.memo(MyComponent, areEqual);


*/