 /*
import React, { useState, useEffect } from 'react';
import { MdMoreHoriz } from 'react-icons/md';
import '../settingButtonAccount/settingButtonAccount.css';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../../firebase/firebase';
import { query, collection, where, getDocs, deleteDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

function SettingButtonAccount({ user1 }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Save the selected language to localStorage
    setIsLanguageMenuOpen(false); // Close the language menu
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen && !event.target.closest('.modalContSetting')) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const goSuscription = () => {
    navigate('/Suscription');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  // Function to delete user data from Firestore collections
  const handleAccountDelete = async () => {
    try {
      // Show a confirmation alert before proceeding with account deletion
      const confirmed = window.confirm(
        'Are you sure you want to delete your account? This action cannot be undone.'
      );

      if (!confirmed) {
        return; // User canceled account deletion
      }

      const uid = auth.currentUser.uid;

      // Delete user-related data from Firestore collections
      const [companyDocs, userDocs] = await Promise.all([
        getDocs(query(collection(db, 'company'), where('uid', '==', uid))),
        getDocs(query(collection(db, 'infoUser'), where('uid', '==', uid))),
      ]);

      // Delete documents if they exist
      companyDocs.forEach(async (doc) => await deleteDoc(doc.ref));
      userDocs.forEach(async (doc) => await deleteDoc(doc.ref));

      // Delete the user account
      await auth.currentUser.delete();

      // Additional cleanup or redirection logic here
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <button className="buttlogo" onClick={handleButtonClick}>
        <MdMoreHoriz className="search-icon" size={32} />
      </button>
      {isModalOpen && (
        <div className="modalContSetting">
          <p className="texEditA" onClick={handleAccountDelete}>
            {t('Account Delete')}
          </p>
          <p className="texEditA" onClick={goSuscription}>
            {t('Subscription')}
          </p>
          

          <button className="btn" onClick={toggleLanguageMenu}>Language</button>
          {isLanguageMenuOpen && (
            <>
              <button className="btn language-btn" onClick={() => changeLanguage('en')}>English</button>
              <button className="btn language-btn" onClick={() => changeLanguage('es')}>Español</button>
            </>
          )}

          <p className="texEditA" onClick={handleLogout}>
            {t('Log out')}
          </p>
        </div>
      )}
    </div>
  );
}

export default SettingButtonAccount;
 
 */ 
import React, { useState, useEffect } from 'react';
import { MdMoreHoriz } from 'react-icons/md';
import '../settingButtonAccount/settingButtonAccount.css';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../../firebase/firebase';
import { query, collection, where, getDocs, deleteDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

function SettingButtonAccount({ user1 }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Save the selected language to localStorage
    setIsLanguageMenuOpen(false); // Close the language menu
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen && !event.target.closest('.modalContSetting')) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const goSuscription = () => {
    navigate('/Suscription');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  // Function to delete user data from Firestore collections
  const handleAccountDelete = async () => {
    try {
      // Show a confirmation alert before proceeding with account deletion
      const confirmed = window.confirm(
        'Are you sure you want to delete your account? This action cannot be undone.'
      );

      if (!confirmed) {
        return; // User canceled account deletion
      }

      const uid = auth.currentUser.uid;

      // Delete user-related data from Firestore collections
      const [companyDocs, userDocs] = await Promise.all([
        getDocs(query(collection(db, 'company'), where('uid', '==', uid))),
        getDocs(query(collection(db, 'infoUser'), where('uid', '==', uid))),
      ]);

      // Delete documents if they exist
      companyDocs.forEach(async (doc) => await deleteDoc(doc.ref));
      userDocs.forEach(async (doc) => await deleteDoc(doc.ref));

      // Delete the user account
      await auth.currentUser.delete();

      // Additional cleanup or redirection logic here
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <button className="buttlogo" onClick={handleButtonClick}>
        <MdMoreHoriz className="search-icon" size={32} />
      </button>
      {isModalOpen && (
        <div className="modalContSetting">
          <p className="texEditA" onClick={handleAccountDelete}>
            {t('Account Delete')}
          </p>
          <p className="texEditA" onClick={goSuscription}>
            {t('Subscription')}
          </p>
          

          <button className="btn" onClick={toggleLanguageMenu}>Language</button>
          {isLanguageMenuOpen && (
            <>
              <button className="btn language-btn" onClick={() => changeLanguage('en')}>English</button>
              <button className="btn language-btn" onClick={() => changeLanguage('es')}>Español</button>
            </>
          )}

          <p className="texEditA" onClick={handleLogout}>
            {t('Log out')}
          </p>
        </div>
      )}
    </div>
  );
}

export default SettingButtonAccount;
