import Edit from "./pages/Edit";
import './App.css';
import HeaderBlock from './pages/HeaderBlock'
import ContactUs from './pages/ContactUs'
import QueSomos from './pages/QueSomos'
import SliderBar from './pages/SliderBar';
import Login from './pages/Login';
import SignUp from './pages/SignUp'
import Prueva from './pages/Prueva'
import Search  from './pages/pageSearch/PageSearch';
import SetUpAccount from './pages/SetUpAccount'
import EditPost from './components/compPerfilUser/perfilUserPost/EditPost';
import SetUpAccCompany from './pages/company/SetUpAccCompany'
import Chat  from './pages/chat/Chat';
import NotFound from './pages/NotFound';
import Payments from './pages/payments/payments';
import BodyBuscar from './pages/buscadorFeed/bodyBuscar/bodyBuscar';
import BossMangerPayment from './pages/payments/bossMangerPayment/bossMangerPayment'
import VerificationAccount from './pages/Bloqueos/verificationAccount/verificationAccount'
import VerificationProfile from "./pages/Bloqueos/verificationProfile/verificationProfile";
import VerificationPost from "./pages/Bloqueos/verificationPost/verificationPost";
import VerificationEditAccount from "./pages/Bloqueos/verificationEditAccount/verificationEditAccount";
import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, Navigate} from "react-router-dom";
import { AuthContext } from './backEndFireBase/AuthContext';
import { Protected } from './components/auth/Protected';
import { isIOS } from 'react-device-detect';
import NoWifi from './pages/NoWifi/NoWifi';
import CompanyCv from './pages/CompanyCv/CompanyCvMain';
import UserCv from './pages/UserCvForm/UserCvMain';
import MainInteresJobs from './pages/MainInteresJobs/MainInteresJobs'

function App() {

  const { user, setUser } = useContext(AuthContext);
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    if (isIOS) {
      window.location.href = "https://apps.apple.com/app/id6480523318";
    }
  }, []);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    // Check the initial online status
    handleOnlineStatus();

    // Listen for changes in the network status
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (isIOS) {
      window.location.href = "https://apps.apple.com/app/id6480523318";
    }
  }, []);


  if (!isOnline) {
    return <NoWifi />;
  }

  return (
      
    <AuthContext.Provider value={{ user, setUser }}>
        <Routes> 
        <Route path="/" element={<Navigate replace to="/SearchJob" />} />

                        <Route
                                  exact path="/TipeAccount"
                                      element={(
                                        <>
                                           <Protected>
                                                 <VerificationAccount/>
                                          </Protected>
                                        </>
                                      )}
                                    />    

                                     <Route 
                                      exact path="/CompanyContact"
                                          element={(
                                            <>
                                            <CompanyCv/>
                                            </>
                                          )}
                                        /> 
                                          

                                        <Route 
                                        exact path="/Cv"
                                            element={(
                                              <>      
                                                 <UserCv/>
                                              </>
                                            )}
                                          />
         



                                  <Route 
                                  exact path="/Home"
                                      element={(
                                        <>
                                        <SliderBar />          
                                        <HeaderBlock/>
                                          
                                        </>
                                      )}
                                    />
         
  

                               

                            <Route         
                                exact path="/ContactUs"
                                    element={(
                                      <>
                                        <SliderBar/>   
                                          <ContactUs/>
                                    </>
                                      )}
                                  />     
 

                              <Route
                                    exact path="/termsAndCondition"
                                        element={(
                                          <>
                                            <SliderBar/> 
                                            <QueSomos/>
                                            
                                          </>
                                        )}
                                      />                 

                              <Route
                                  exact path="/Login"
                                      element={(
                                        <>
                                          <SliderBar/> 
                                          <Login/>
                                        </>
                                      )}
                                    />     



                              <Route
                                  exact path="/SignUp"
                                      element={(
                                        <>
                                          <SliderBar/> 
                                          <SignUp/>
                                        </>
                                      )}
                                    />     



                              <Route
                                  exact path="/Prueva"
                                      element={(
                                        <>
                                          <SliderBar/> 
                                          <Prueva/>
                                        </>
                                      )}
                                    />

                              <Route
                                  exact path="/SetUpAccount"
                                      element={(
                                        <>
                                          <SetUpAccount/>
                                        </>
                                      )}
                                    />


                                <Route
                                  exact path="/CompanyAccount"
                                      element={(
                                        <>
                                          <SetUpAccCompany/>
                                        </>
                                      )}
                                    />


                            
      
                              <Route
                                  exact path="/Perfil/:uid"
                                      element={(
                                        <>
                                           <Protected>
                                                 <VerificationProfile/>
                                          </Protected>
                                        </>
                                      )}
                                    />     


                                    


                                    <Route
                                      exact path="/Post"
                                      element={(
                                        <>
                                           <Protected>
                                                 <VerificationPost/>
                                          </Protected>
                                        </>
                                      )}
                                    />     


                                    <Route
                                      exact path="/EditAccount"
                                      element={(
                                        <>
                                           <Protected>
                                                 <VerificationEditAccount/>
                                          </Protected>
                                        </>
                                      )}
                                    />   



                              <Route
                                  exact path="/edit-post/:postI"
                                      element={(
                                        <>
                                           <Protected>
                                                 <EditPost/>
                                          </Protected>
                                        </>
                                      )}
                                    />     


 
                                <Route
                                  exact path="/SearchJob"
                                      element={(
                                        <>
                                          <Search/>
                                        </>
                                      )}
                                    />   
                                    

                               <Route
                                  exact path="/Search"
                                      element={(
                                        <>
                                          <BodyBuscar/>
                                        </>
                                      )}
                                    /> 


                                <Route
                                  exact path="/employmentInterest"
                                      element={(
                                        <>
                                          <MainInteresJobs/> 
                                        </>
                                      )}
                                    /> 





                                    <Route
                                  exact path="/Chat"
                                      element={(
                                        <>
                                          <Chat/>
                                        </>
                                      )}
                                    />   


                             
                              <Route
                                  exact path="/payments"
                                      element={(
                                        <>
                                          <Payments/>
                                        </>
                                      )}
                                    />   


                                <Route
                                  exact path="/Suscription"
                                      element={(
                                        <>
                                          <BossMangerPayment/>
                                        </>
                                      )}
                                    />
                                    

                             <Route path="*" element={
                                   <>
                                     <NotFound />
                                   </>
                                  } />
        
          
          <Route exact path="/edit/:rowIndex" element={<Edit/>} />
          
        </Routes>
        </AuthContext.Provider>
  );
};

export default App;











 