
/*

import React from 'react';
import { FaUser, FaMoneyBillWave, FaBriefcase, FaClock, FaLaptopHouse } from 'react-icons/fa';
import CompanyCv from '../CompanyCv/CompanyCv';
import UserCv from '../UserCv/UserCv.js';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import './UiCards.css';
import ProfileImage from '../ProfileImage/ProfileImage.js'


function UiCards({ currentCard, currentUid }) {
  if (!currentCard) return null;

  const hasDetails = currentCard.firstName || currentCard.nameCompany || currentCard.positionJob || currentCard.descriptionCompany || currentCard.title || currentCard.description || currentCard.inputText || currentCard.salary || currentCard.city || currentCard.location || currentCard.uid;

  return (
    <div className="post-details-43">

      <ProfileImage/>
      
       
      <div className="post-column-user-43">
        {currentCard.city && (
          <div className="post-likes-43">{currentCard.city}</div>
        )}
        {currentCard.firstName ? (
          <SendMessage uidThePost={currentCard.uid} currentUid={currentUid} />
        ) : (
          <SendMessageCompany uidThePost={currentCard.uid} currentUid={currentUid} />
        )}
      </div>

      
      <div className="post-column">
        {currentCard.firstName || currentCard.nameCompany ? (
          <div className="post-icon-text">
            <FaUser size={20} />
            <div className="post-des2-43">{currentCard.firstName || currentCard.nameCompany}</div>
          </div>
        ) : null}
        {currentCard.salary && (
          <div className="post-icon-text">
            <FaMoneyBillWave size={20} />
            <p className="post-des2-43">{currentCard.salary}</p>
          </div>
        )}
      </div>

      <div className="post-columnr">
        {currentCard.positionJob || currentCard.descriptionCompany || currentCard.title ? (
          <div className="post-icon-text">
            <FaBriefcase size={20} />
            <p className="post-des2-43">{currentCard.positionJob || currentCard.descriptionCompany || currentCard.title}</p>
          </div>
        ) : null}
      </div>

      <div className="post-column">
        {currentCard.jobType && (
          <div className="post-icon-text">
            <FaClock size={20} />
            <p className="post-des2-43">{currentCard.jobType}</p>
          </div>
        )}
        {currentCard.workModality && (
          <div className="post-icon-text">
            <FaLaptopHouse size={20} />
            <p className="post-des2-43">{currentCard.workModality}</p>
          </div>
        )}
      </div>

   
      {currentCard.description || currentCard.inputText ? (
        <div className="post-columnd">
          <div className="post-icon-text">
            <p className="post-des2-43">{currentCard.description || currentCard.inputText}</p>
          </div>
        </div>
      ) : null}

     
      <div className="cv-container">
        <CompanyCv key={`company-${currentCard.uid}`} uid={currentCard.uid} />
        <UserCv key={`user-${currentCard.uid}`} uid={currentCard.uid} />
      </div>
    </div>
  );
}

export default UiCards;


*/

import React from 'react';
import { FaUser, FaMoneyBillWave, FaBriefcase, FaClock, FaLaptopHouse } from 'react-icons/fa';
import CompanyCv from '../CompanyCv/CompanyCv';
import UserCv from '../UserCv/UserCv.js';
import SendMessage from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessage/SendMessage';
import SendMessageCompany from '../../../../src/components/comChat/chatFeed/chatListFeed/sendMessageCompany/SendMessageCompany';
import './UiCards.css';
import ProfileImage from '../ProfileImage/ProfileImage.js'


function UiCards({ currentCard, currentUid }) {
  if (!currentCard) return null;

  const hasDetails = currentCard.firstName 
  || currentCard.nameCompany || currentCard.positionJob 
  || currentCard.descriptionCompany || currentCard.title 
  || currentCard.description || currentCard.inputText 
  || currentCard.salary || currentCard.city 
  || currentCard.location || currentCard.uid;

  return (
    <div className="post-details-43">

      <ProfileImage uid={currentCard.uid}/>
      
      {/* User/Company Details */}
      <div className="post-column-user-43">
        {currentCard.city && (
          <div className="post-likes-43">{currentCard.city}</div>
        )}
        {currentCard.firstName ? (
          <SendMessage uidThePost={currentCard.uid} currentUid={currentUid} />
        ) : (
          <SendMessageCompany uidThePost={currentCard.uid} currentUid={currentUid} />
        )}
      </div>

      {/* Profile Details */}
      <div className="post-column">
        {currentCard.firstName || currentCard.nameCompany ? (
          <div className="post-icon-text">
            <FaUser size={20} />
            <div className="post-des2-43">{currentCard.firstName || currentCard.nameCompany}</div>
          </div>
        ) : null}
        {currentCard.salary && (
          <div className="post-icon-text">
            <FaMoneyBillWave size={20} />
            <p className="post-des2-43">{currentCard.salary}</p>
          </div>
        )}
      </div>

      <div className="post-columnr">
        {currentCard.positionJob || currentCard.descriptionCompany || currentCard.title ? (
          <div className="post-icon-text">
            <FaBriefcase size={20} />
            <p className="post-des2-43">{currentCard.positionJob || currentCard.descriptionCompany || currentCard.title}</p>
          </div>
        ) : null}
      </div>

      <div className="post-column">
        {currentCard.jobType && (
          <div className="post-icon-text">
            <FaClock size={20} />
            <p className="post-des2-43">{currentCard.jobType}</p>
          </div>
        )}
        {currentCard.workModality && (
          <div className="post-icon-text">
            <FaLaptopHouse size={20} />
            <p className="post-des2-43">{currentCard.workModality}</p>
          </div>
        )}
      </div>

      {/* Description */}
      {currentCard.description || currentCard.inputText ? (
        <div className="post-columnd">
          <div className="post-icon-text">
            <p className="post-des2-43">{currentCard.description || currentCard.inputText}</p>
          </div>
        </div>
      ) : null}

      {/* CV Components */}
      <div className="cv-container">
        <CompanyCv key={`company-${currentCard.uid}`} uid={currentCard.uid} />
        <UserCv key={`user-${currentCard.uid}`} uid={currentCard.uid} />
      </div>
    </div>
  );
}

export default UiCards;
