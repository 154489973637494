/*

import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase'; // Import your Firebase configuration
 
import SuscriptionOffer from '../../../../src/pages/payments/suscriptionOffer/suscriptionOffer'

import PostUser from '../../../../src/pages/post/user/user'
import PostCompany from '../../../../src/pages/post/company/company'

function VerificationProfile() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const currentUid = auth.currentUser.uid; // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, []);

  return (
    <div>
      {formType === null && <SuscriptionOffer/>}
      {formType === 'company' &&  <PostCompany/>}
      {formType === 'infoUser' &&  <PostUser/>}
    </div>
  );
}

export default VerificationProfile;

*/
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../../src/firebase/firebase'; // Import your Firebase configuration
import SuscriptionOffer from '../../../../src/pages/payments/suscriptionOffer/suscriptionOffer'
import PostUser from '../../../../src/pages/post/user/user'
import PostCompany from '../../../../src/pages/post/company/company'
import FreeTrial from '../../Suscription/FreeTrial/FreeTrial'
import Login from '../../../pages/Login';

function VerificationProfile() {
  const [formData, setFormData] = useState(null);
  const [formType, setFormType] = useState(null);
  const [currentUid, setCurrentUid] = useState(null); // Add this line

  useEffect(() => {
    const fetchData = async () => {
       setCurrentUid (auth.currentUser.uid); // Get the current user's uid

      if (!currentUid) {
        console.log('No UID found');
        return;
      }

      const companyQuery = query(collection(db, 'company'), where('uid', '==', currentUid));
      const companyDocs = await getDocs(companyQuery);

      if (!companyDocs.empty) {
        const companyData = companyDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('company');
        setFormData(companyData);
        return;
      }

      const userQuery = query(collection(db, 'infoUser'), where('uid', '==', currentUid));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        const userData = userDocs.docs.map(doc => doc.data())[0]; // Assuming the first document
        setFormType('infoUser');
        setFormData(userData);
        return;
      }

      setFormType(null); // Set formType to null if the user does not exist in both collections
    };

    fetchData();
  }, [currentUid]);

  return (
    <div>
      {formType === null && <Login/>}
      {formType === 'company' &&  <FreeTrial currentUid={currentUid}/>}
      {formType === 'infoUser' &&  <PostUser/>}
    </div>
  );
}

export default VerificationProfile;