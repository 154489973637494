/*



import React, { useState, useEffect } from 'react';
import './compPerfilUser.css';
import { auth } from '../../firebase/firebase';  
import { collection, getDocs, where, query, doc, updateDoc  } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import { GrMore } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './CartaPerfilUser.css'
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { MdMoreHoriz } from "react-icons/md";

import CountFollow from './countFollow/countFollow'

function CartaPerfilUser({ handleEdit }) {
  const [val, setVal] = useState([]);
  const { uid } = useParams(); // Extract UID from the path
  const currentUser = auth.currentUser; // Get the current user from Firebase Auth

  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (uid) {
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const q2 = query(collection(db, 'company'), where('uid', '==', uid));

        try {
          const querySnapshot1 = await getDocs(q1);
          const querySnapshot2 = await getDocs(q2);
          
          let userData = {};
          let companyData = {};

          querySnapshot1.forEach(doc => {
            userData = doc.data();
          });

          querySnapshot2.forEach(doc => {
            companyData = doc.data();
          });

          // Combining data from both collections
          const combinedData = { ...userData, ...companyData };

          setVal([combinedData]); // Assuming you want to maintain the array structure
        } catch (error) {
          console.error('Error getting user data:', error);
        }
      }
    };

    getData();
  }, [uid]);

  function goQuery() {
    navigate(`/EditAccount`);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
  
    const userDoc = doc(db, 'infoUser', uid); // Replace 'infoUser' with the correct collection name
    await updateDoc(userDoc, {
      imgPerfil: fileURL
    });
  
    setSelectedFile(fileURL);
  };

  return (
    <div className="profiles-containe5">
      {val.map(values => (
        <div className="twitter-profile" key={values.uid}>
          {currentUser && currentUser.uid === uid && (
            <button onClick={goQuery}
              className="buttlogoPro">
              <MdMoreHoriz className="search-icon" size={32} />
            </button>
          )}

          {values.imgBack && (
            <div style={{ 
              width: '100%', 
              height: '200PX', 
              backgroundImage: `url(${values.imgBack})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center', 
              borderRadius:'10PX'
            }}>
            </div>
          )}
          
          <div className="profile-details">

            <div className="profile-image5">
              {values.imgPerfil ? (
                <img 
                  className="conPimg" 
                  src={selectedFile || values.imgPerfil} 
                  alt="Profile" 
                  onError={(e) => { e.target.onerror = null; e.target.src="default_profile_icon.png"; }}
                />
              ) : (
                <FaUser size={32} className="search-icon" />
              )}
              <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className="contProfileT"> 
                {values.nameCompany && (
                  <div className="profile-companyName">{values.nameCompany}</div>
                )}
                {values.firstName && (
                  <div className="profile-companyName">{values.firstName}</div>
                )}
                {values.city && (
                  <div className="profile-city-tex">@{values.city}</div>
                )}
                {values.username && (
                  <div className="use32">{values.username}</div>
                )}
              </div>

              <div className="profile-container-names">
                {values.positionJob && (
                  <div className="descri">{values.positionJob}</div>
                )}
                {values.descriptionSkills && (
                  <p className="descri">{values.descriptionSkills}</p>
                )}
                {values.descriptionCompany && (
                  <p className="positionTitle">{values.descriptionCompany}</p>
                )}

                  <CountFollow  userUid={values.uid}/>
              </div>
              
            </div>

            <div className="conBeditP"> 
              {currentUser && currentUser.uid === uid && (
                <button
                  onClick={() => handleEdit(values)}
                  className="clickalicious-button2"
                >
                  <IconContext.Provider style={{color: 'blue', padding: '35px'}} value={{ size: '18px' }}>
                    <GrMore/> 
                  </IconContext.Provider> 
                </button>
              )}
              
            </div>

            
          </div>
        </div>
      ))}
    </div>
  );
}

export default CartaPerfilUser;


 



 
import React, { useState, useEffect } from 'react';
import './compPerfilUser.css';
import { auth } from '../../firebase/firebase';  
import { collection, getDocs, where, query, doc, updateDoc  } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import { GrMore } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './CartaPerfilUser.css'
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { MdMoreHoriz } from "react-icons/md";

import CountFollow from './countFollow/countFollow'

function CartaPerfilUser({ handleEdit }) {
  const [val, setVal] = useState([]);
  const { uid } = useParams(); // Extract UID from the path
  const currentUser = auth.currentUser; // Get the current user from Firebase Auth

  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (uid) {
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const q2 = query(collection(db, 'company'), where('uid', '==', uid));

        try {
          const querySnapshot1 = await getDocs(q1);
          const querySnapshot2 = await getDocs(q2);
          
          let userData = {};
          let companyData = {};

          querySnapshot1.forEach(doc => {
            userData = doc.data();
          });

          querySnapshot2.forEach(doc => {
            companyData = doc.data();
          });

          // Combining data from both collections
          const combinedData = { ...userData, ...companyData };

          setVal([combinedData]); // Assuming you want to maintain the array structure
        } catch (error) {
          console.error('Error getting user data:', error);
        }
      }
    };

    getData();
  }, [uid]);

  function goQuery() {
    navigate(`/EditAccount`);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
  
    const userDoc = doc(db, 'infoUser', uid); // Replace 'infoUser' with the correct collection name
    await updateDoc(userDoc, {
      imgPerfil: fileURL
    });
  
    setSelectedFile(fileURL);
  };

  return (
    <div className="profiles-containe5">
      {val.map(values => (
        <div className="twitter-profile" key={values.uid}>
          {currentUser && currentUser.uid === uid && (
            <button onClick={goQuery}
              className="buttlogoPro">
              <MdMoreHoriz className="search-icon" size={32} />
            </button>
          )}

          {values.imgBack && (
            <div style={{ 
              width: '100%', 
              height: '200PX', 
              backgroundImage: `url(${values.imgBack})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center', 
              borderRadius:'10PX'
            }}>
            </div>
          )}
          
          <div className="profile-details">

            <div className="profile-image5">
              {values.imgPerfil ? (
                <img 
                  className="conPimg" 
                  src={selectedFile || values.imgPerfil} 
                  alt="Profile" 
                  onError={(e) => { e.target.onerror = null; e.target.src="default_profile_icon.png"; }}
                />
              ) : (
                <FaUser size={32} className="search-icon" />
              )}
              <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className="contProfileT"> 
                {values.nameCompany && (
                  <div className="profile-companyName">{values.nameCompany}</div>
                )}
                {values.firstName && (
                  <div className="profile-companyName">{values.firstName}</div>
                )}
                {values.city && (
                  <div className="profile-city-tex">@{values.city}</div>
                )}
                {values.username && (
                  <div className="use32">{values.username}</div>
                )}
              </div>

              <div className="profile-container-names">
                {values.positionJob && (
                  <div className="descri">{values.positionJob}</div>
                )}
                {values.descriptionSkills && (
                  <p className="descri">{values.descriptionSkills}</p>
                )}
                {values.descriptionCompany && (
                  <p className="positionTitle">{values.descriptionCompany}</p>
                )}

                  <CountFollow  userUid={values.uid}/>
              </div>
              
            </div>

            <div className="conBeditP"> 
              {currentUser && currentUser.uid === uid && (
                <button
                  onClick={() => handleEdit(values)}
                  className="clickalicious-button2"
                >
                  <IconContext.Provider style={{color: 'blue', padding: '35px'}} value={{ size: '18px' }}>
                    <GrMore/> 
                  </IconContext.Provider> 
                </button>
              )}
              
            </div>

            
          </div>
        </div>
      ))}
    </div>
  );
}

export default CartaPerfilUser;
*/

import React, { useState, useEffect } from 'react';
import './compPerfilUser.css';
import { auth } from '../../firebase/firebase';  
import { collection, getDocs, where, query, doc, updateDoc  } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import { GrMore } from "react-icons/gr";  
import { IconContext } from "react-icons";
import './CartaPerfilUser.css'
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { MdMoreHoriz } from "react-icons/md";

import CountFollow from './countFollow/countFollow'

function CartaPerfilUser({ handleEdit }) {
  const [val, setVal] = useState([]);
  const { uid } = useParams(); // Extract UID from the path
  const currentUser = auth.currentUser; // Get the current user from Firebase Auth

  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (uid) {
        const q1 = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const q2 = query(collection(db, 'company'), where('uid', '==', uid));

        try {
          const querySnapshot1 = await getDocs(q1);
          const querySnapshot2 = await getDocs(q2);
          
          let userData = {};
          let companyData = {};

          querySnapshot1.forEach(doc => {
            userData = doc.data();
          });

          querySnapshot2.forEach(doc => {
            companyData = doc.data();
          });

          // Combining data from both collections
          const combinedData = { ...userData, ...companyData };

          setVal([combinedData]); // Assuming you want to maintain the array structure
        } catch (error) {
          console.error('Error getting user data:', error);
        }
      }
    };

    getData();
  }, [uid]);

  function goQuery() {
    navigate(`/EditAccount`);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
  
    const userDoc = doc(db, 'infoUser', uid); // Replace 'infoUser' with the correct collection name
    await updateDoc(userDoc, {
      imgPerfil: fileURL
    });
  
    setSelectedFile(fileURL);
  };

  return (
    <div className="profiles-containe5">
      {val.map(values => (
        <div className="twitter-profile" key={values.uid}>
          {currentUser && currentUser.uid === uid && (
            <button onClick={goQuery}
              className="buttlogoPro">
              <MdMoreHoriz className="search-icon" size={32} />
            </button>
          )}

          {values.imgBack && (
            <div style={{ 
               width: '100%',
            
                height: '200px', // Make sure it's 'px' instead of 'PX'
                backgroundImage: `url(${values.imgBack})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '10px',
                display: 'flex',  // Make the container flexible if it has child elements
                alignItems: 'center', // Vertically center content
                justifyContent: 'center', // Horizontally center content (optional)
                overflow: 'hidden',
            }}>
            </div>
          )}
          
          <div className="profile-details">

            <div className="profile-image5">
              {values.imgPerfil ? (
                <img 
                  className="conPimg" 
                  src={selectedFile || values.imgPerfil} 
                  alt="Profile" 
                  onError={(e) => { e.target.onerror = null; e.target.src="default_profile_icon.png"; }}
                />
              ) : (
                <FaUser size={32} className="search-icon" />
              )}
              <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className="contProfileT"> 
                {values.nameCompany && (
                  <div className="profile-companyName">{values.nameCompany}</div>
                )}
                {values.firstName && (
                  <div className="profile-companyName">{values.firstName}</div>
                )}
                {values.city && (
                  <div className="profile-city-tex">@{values.city}</div>
                )}
                {values.username && (
                  <div className="use32">{values.username}</div>
                )}
                <div className="mobile-only">
                     <CountFollow  userUid={values.uid}/>
                </div>
              </div>

              <div className="profile-container-names">
                {values.positionJob && (
                  <div className="descri">{values.positionJob}</div>
                )}
                {values.descriptionSkills && (
                  <p className="descri">{values.descriptionSkills}</p>
                )}
                {values.descriptionCompany && (
                  <p className="positionTitle">{values.descriptionCompany}</p>
                )}
                  
              </div>
              
            </div>
            <div className="pc-only">
              <CountFollow userUid={values.uid} />
            </div>

            <div className="conBeditP"> 
              {currentUser && currentUser.uid === uid && (
                <button
                  onClick={() => handleEdit(values)}
                  className="clickalicious-button2"
                >
                  <IconContext.Provider style={{color: 'blue', padding: '35px'}} value={{ size: '18px' }}>
                    <GrMore/> 
                  </IconContext.Provider> 
                </button>
              )}
              
            </div>

           
          </div>
        </div>
      ))}
    </div>
  );
}

export default CartaPerfilUser;


 



 