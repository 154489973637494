/*


import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './IconName.css';
import { db } from '../../../firebase/firebase';

import LastMessage from '../lastMessage/lastMessage';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


function IconName({ profilesUids, searchQuery , currentUid, isMobile, selectedChat }) {
  const [userInfos, setUserInfos] = useState([]);
  const [companyInfos, setCompanyInfos] = useState([]);
  const navigate = useNavigate();


  // Fetch data by UID from Firestore
  useEffect(() => {
    const fetchDataByUID = async (uid) => {
      const userQuerySnapshot = await getDocs(query(collection(db, "infoUser"), where("uid", "==", uid)));
      const companyQuerySnapshot = await getDocs(query(collection(db, "company"), where("uid", "==", uid)));
      const userData = userQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const companyData = companyQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return { userData, companyData };
    };

    // Fetch data for all profiles
    const fetchAllData = async () => {
      const users = [];
      const companies = [];

      for (const uid of profilesUids) {
        const { userData, companyData } = await fetchDataByUID(uid);
        users.push(...userData);
        companies.push(...companyData);
      }

      setUserInfos(users);
      setCompanyInfos(companies);
    };

    fetchAllData();
  }, [profilesUids]);

  // Apply filter only if searchQuery exists
  const filteredUserInfos = searchQuery ? userInfos.filter(user => 
    (user.firstName && user.firstName.toLowerCase().includes(searchQuery.toLowerCase())) || 
    (user.lastName && user.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
  ) : userInfos;

  const filteredCompanyInfos = searchQuery ? companyInfos.filter(company => 
    company.nameCompany && company.nameCompany.toLowerCase().includes(searchQuery.toLowerCase())
  ) : companyInfos;

  const clearData = () => {
    setUserInfos([]);
    setCompanyInfos([]);
  };

  return (
    <div className="listChatcon">
      {filteredUserInfos.map((user, index) => (
        <div  className={`chatItem1 ${selectedChat === index ? 'selected' : ''}`}   key={`user-${index}`}>

            {user.imgPerfil ? (
              <img 
                className="profileImage" 
                src={user.imgPerfil} 
                alt="User" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src=`${FaUser}`; // Replace with your default image path
                }}
              />
            ) : (
              <FaUser size={22} className="profileImage" />
            )}

          <div className="chatText">
          {!isMobile || !selectedChat ? (
            <p 
              className="userName"
               
              onClick={() => {
                if (!isMobile) {
                  navigate(`/Perfil/${user.uid}`)
                }
              }}>
              {`${user.firstName} ${user.lastName}`}
            </p>
          ) : null}

              {!isMobile || !selectedChat ? (
                <LastMessage currentUid={currentUid} profilesUids={profilesUids}/> 
              ) : null}

          </div>
        </div>
      ))}
      {filteredCompanyInfos.map((company, index) => (
        <div  className={`chatItem1 ${selectedChat === index ? 'selected' : ''}`} >
          

          {company.imgPerfil ? (
              <img 
                className="profileImage" 
                src={company.imgPerfil} 
                alt="Company" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src=`${FaUser}`; // Replace with your default image path
                }}
              />
            ) : (
              <FaUser size={22} className="profileImage" />
            )}
          <div className="chatText">
                {!isMobile || !selectedChat ? (
                    <p 
                      className="userName"
                      onClick={() => {
                        if (!isMobile) {
                          navigate(`/Perfil/${company.uid}`)
                        }
                      }}>
                      {company.nameCompany}
                    </p>
                  ) : null}

                  {!isMobile || !selectedChat ? (
                      <LastMessage currentUid={currentUid}  profilesUids={company.uid}/> 
                  ) : null}

          </div>
        </div>
      ))}
    </div>
  );
}

export default IconName;

*/
import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { ref, onValue, getDatabase } from 'firebase/database';
import './IconName.css';
import { db } from '../../../firebase/firebase';
import LastMessage from '../lastMessage/lastMessage';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function IconName({ profilesUids, searchQuery, currentUid, isMobile, selectedChat }) {
  const [userInfos, setUserInfos] = useState([]);
  const [companyInfos, setCompanyInfos] = useState([]);
  const [userStatuses, setUserStatuses] = useState({}); // Store user statuses
  const navigate = useNavigate();

  // Fetch user and company data by UID from Firestore
  useEffect(() => {
    const fetchDataByUID = async (uid) => {
      const userQuerySnapshot = await getDocs(query(collection(db, "infoUser"), where("uid", "==", uid)));
      const companyQuerySnapshot = await getDocs(query(collection(db, "company"), where("uid", "==", uid)));
      const userData = userQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const companyData = companyQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return { userData, companyData };
    };

    const fetchAllData = async () => {
      const users = [];
      const companies = [];

      for (const uid of profilesUids) {
        const { userData, companyData } = await fetchDataByUID(uid);
        users.push(...userData);
        companies.push(...companyData);
      }

      setUserInfos(users);
      setCompanyInfos(companies);
    };

    fetchAllData();
  }, [profilesUids]);

  // Fetch user statuses from Firebase Realtime Database
  useEffect(() => {
    const dbRealtime = getDatabase();
    const statusesRef = ref(dbRealtime, 'status');

    profilesUids.forEach(uid => {
      const userStatusRef = ref(dbRealtime, `status/${uid}`);
      onValue(userStatusRef, (snapshot) => {
        const status = snapshot.val();
        setUserStatuses(prevStatuses => ({
          ...prevStatuses,
          [uid]: status || "offline"
        }));
      });
    });
  }, [profilesUids]);

  const filteredUserInfos = searchQuery ? userInfos.filter(user => 
    (user.firstName && user.firstName.toLowerCase().includes(searchQuery.toLowerCase())) || 
    (user.lastName && user.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
  ) : userInfos;

  const filteredCompanyInfos = searchQuery ? companyInfos.filter(company => 
    company.nameCompany && company.nameCompany.toLowerCase().includes(searchQuery.toLowerCase())
  ) : companyInfos;

  const clearData = () => {
    setUserInfos([]);
    setCompanyInfos([]);
  };

  return (
    <div className="listChatcon">
      {filteredUserInfos.map((user, index) => {
        const userStatus = userStatuses[user.uid] || "offline"; // Get the user's status
        const statusColor = userStatus === "online" ? 'green' : 'orange'; // Set color based on status

        return (
          <div className={`chatItem1 ${selectedChat === index ? 'selected' : ''}`} key={`user-${index}`}>
            {user.imgPerfil ? (
              <img 
                className="profileImage" 
                src={user.imgPerfil} 
                alt="User" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src=`${FaUser}`; 
                }}
              />
            ) : (
              <FaUser size={22} className="profileImage" />
            )}

            <div className="chatText">
              {!isMobile || !selectedChat ? (
                <p className="userName"
                  onClick={() => {
                    if (!isMobile) {
                      navigate(`/Perfil/${user.uid}`)
                    }
                  }}>
                  {`${user.firstName} ${user.lastName} `}
                </p>
              ) : null}

              {!isMobile || !selectedChat ? (
                <LastMessage currentUid={currentUid} profilesUids={profilesUids}/> 
              ) : null}
            </div>
            <div className="statusDot" style={{ backgroundColor: statusColor }}></div>
          </div>
        );
      })}

      {filteredCompanyInfos.map((company, index) => {
        const companyStatus = userStatuses[company.uid] || "offline"; // Get the company's status
        const statusColor = companyStatus === "online" ? 'green' : 'orange';

        return (
          <div className={`chatItem1 ${selectedChat === index ? 'selected' : ''}`} key={`company-${index}`}>
            {company.imgPerfil ? (
              <img 
                className="profileImage" 
                src={company.imgPerfil} 
                alt="Company" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src=`${FaUser}`; 
                }}
              />
            ) : (
              <FaUser size={22} className="profileImage" />
            )}
            <div className="chatText">
              {!isMobile || !selectedChat ? (
                <p className="userName"
                  onClick={() => {
                    if (!isMobile) {
                      navigate(`/Perfil/${company.uid}`)
                    }
                  }}>
                  {company.nameCompany}
            
                </p>
              ) : null}

              {!isMobile || !selectedChat ? (
                <LastMessage currentUid={currentUid} profilesUids={company.uid}/> 
              ) : null}
            </div>
            <div className="statusDot" style={{ backgroundColor: statusColor }}></div>
          </div>
        );
      })}
    </div>
  );
}

export default IconName;
