import React, { useState, useEffect } from 'react';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../../../src/firebase/firebase'; // Ensure Firebase is configured correctly
import { FaUser } from 'react-icons/fa';
import './ProfileImage.css';

function ProfileImage({ uid }) {
  const [userInfo, setUserInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch from the infoUser collection
        const userQuery = query(collection(db, 'infoUser'), where('uid', '==', uid));
        const userSnapshot = await getDocs(userQuery);
        const userData = userSnapshot.docs.map(doc => doc.data())[0]; // Assuming one user matches
        console.log('User Data:', userData);

        // Fetch from the company collection
        const companyQuery = query(collection(db, 'company'), where('uid', '==', uid));
        const companySnapshot = await getDocs(companyQuery);
        const companyData = companySnapshot.docs.map(doc => doc.data())[0]; // Assuming one company matches
        console.log('Company Data:', companyData);

        setUserInfo(userData || null);
        setCompanyInfo(companyData || null);
      } catch (err) {
        console.error('Error fetching user or company data:', err);
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    if (uid) {
      fetchUserData();
    } else {
      setError('Invalid UID.');
      setLoading(false);
    }
  }, [uid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!userInfo && !companyInfo) {
    return <div>No data found for this user.</div>;
  }

  return (
    <div className="m54">
      {userInfo && (
        <div className="profile-info">
          {userInfo.imgPerfil ? (
            <img className="im79" src={userInfo.imgPerfil} alt="User Profile" />
          ) : (
            <FaUser size={50} className="ttdefault43"/>
          )}
         
        </div>
      )}
      {companyInfo && (
        <div className="company-info">
          {companyInfo.imgPerfil ? (
            <img className="im79" src={companyInfo.imgPerfil} alt="Company Profile" />
          ) : (
            <FaUser size={50} className="ttdefault43" />
          )}
      
        </div>
      )}
    </div>
  );
}

export default ProfileImage;

