/*

import React, { useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // import your firebase config file
import PostCompany from '../../../../src/pages/post/company/company'
import SuscriptionOffer from '../../../../src/pages/payments/suscriptionOffer/suscriptionOffer'
import { getAuth } from 'firebase/auth';
import './FreeTrial.css'

function FreeTrial({ currentUid }) {
  const [posts, setPosts] = useState([]);
  const [customerEmail, setCustomerEmail] = useState(null);

  const [email, setEmail] = useState(null);

    const auth = getAuth();
    const user = auth.currentUser;

  useEffect(() => {
  

    if (user && user.uid === currentUid) {
      setEmail(user.email); // Get the email from the currentUser object
    }
}, [currentUid]);

  useEffect(() => {
    const fetchData = async () => {
      const subscriptionQuery = query(collection(db, 'subscriptions'), where('customerEmail', '==', user.email));
      const subscriptionDocs = await getDocs(subscriptionQuery);

      if (!subscriptionDocs.empty) {
        const subscriptionData = subscriptionDocs.docs.map(doc => doc.data())[0];
        setCustomerEmail(subscriptionData.customerEmail);
      }

      const postQuery = query(collection(db, 'employeesPost'), where('uid', '==', currentUid));
      const postDocs = await getDocs(postQuery);

      if (!postDocs.empty) {
        const postData = postDocs.docs.map(doc => doc.data());
        setPosts(postData);
      }
    };

    fetchData();
  }, [currentUid]);

  return (
    <div>
      
      {!customerEmail && posts.length <= 3 && 
        <div className="cont-suscription">
        FreeTrial
        {email && <div className="email">Email: {email}</div>}
        <div className="free-posts">You have {3 - posts.length} free posts left.</div>
      </div>
      
        }

      {posts.length < 3 && <PostCompany/>}

      {customerEmail && <PostCompany/>}
      {posts.length >= 3 && !customerEmail && <SuscriptionOffer/>}
    </div>
  )
}

export default FreeTrial;

*/
import React, { useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // import your firebase config file
import PostCompany from '../../../../src/pages/post/company/company'
import SuscriptionOffer from '../../../../src/pages/payments/suscriptionOffer/suscriptionOffer'
import { getAuth } from 'firebase/auth';
import './FreeTrial.css'

function FreeTrial({ currentUid }) {
  const [posts, setPosts] = useState([]);
  const [customerEmail, setCustomerEmail] = useState(null);

  const [email, setEmail] = useState(null);

    const auth = getAuth();
    const user = auth.currentUser;

  useEffect(() => {
  

    if (user && user.uid === currentUid) {
      setEmail(user.email); // Get the email from the currentUser object
    }
}, [currentUid]);

  useEffect(() => {
    const fetchData = async () => {
      const subscriptionQuery = query(collection(db, 'subscriptions'), where('customerEmail', '==', user.email));
      const subscriptionDocs = await getDocs(subscriptionQuery);

      if (!subscriptionDocs.empty) {
        const subscriptionData = subscriptionDocs.docs.map(doc => doc.data())[0];
        setCustomerEmail(subscriptionData.customerEmail);
      }

      const postQuery = query(collection(db, 'employeesPost'), where('uid', '==', currentUid));
      const postDocs = await getDocs(postQuery);

      if (!postDocs.empty) {
        const postData = postDocs.docs.map(doc => doc.data());
        setPosts(postData);
      }
    };

    fetchData();
  }, [currentUid]);

  return (
    <div>
      
      {!customerEmail && posts.length <= 3 && 
        <div className="cont-suscription">
        FreeTrial
        {email && <div className="email">Email: {email}</div>}
        <div className="free-posts">You have {3 - posts.length} free posts left.</div>
      </div>
      
        }

      {posts.length < 3 && <PostCompany/>}

      {customerEmail && <PostCompany/>}
      {posts.length >= 3 && !customerEmail && <SuscriptionOffer/>}
    </div>
  )
}

export default FreeTrial;
